<template>
    <div class="w-full h-full dF fC aS" style="display: inline-block">
        <bh-loading :show="loading" />
        <LeftNavBar
            @bulkDelete="bulkDelete"
            :bulkSelect="true"
            :selectedValue="$store.state.siteplan.currentType"
            @opened="parentClicked"
            class="f1"
            @scrollItem="scrollItem"
            :openNav="openLeftNav"
            :title="selectedAppInitial"
            :imgSrc="'https://bildhive.nyc3.digitaloceanspaces.com/appIcons/home-siteplan.svg'"
            :items="lotList"
            :hasChild="true"
            @optionclick="moreOptions"
            @chosen="selectLot"
        >
        </LeftNavBar>
    </div>
</template>

<script>
import bhLoading from "bh-mod/components/common/Loading";
import LeftNavBar from "bh-mod/components/common/LeftNavBar";
export default {
    components: {LeftNavBar, bhLoading },
    name: "menu-left",
    data() {
        return {
            lots: [],
            deleteObj: {},
            loading: false,
        };
    },
    watch: {
		runScroll(val) {
			let found = this.siteplanLots.find(x => `${x.shapeId}` == `${this.scrollToLot}`)
			setTimeout(() => {
				if (found) {
					const element = document.querySelector(`[data-file-id="${found.id}"]`);
					if (element !== null) {
						element.scrollIntoView();
					}
				}
			}, 100)
		}
	},
    computed: {
		runScroll(){
			return this.$store.state.siteplan.runScroll
		},
		scrollToLot(){
			return this.$store.state.siteplan.scrollToLot
		},
		selectedAppInitial() {
            return (
                (this.$store.state.theApp &&
                    this.$store.state.theApp.media &&
                    this.$store.state.theApp.media.initial) ||
                "SPC"
            );
        },
        childLots() {
            return this.$store.state.siteplan.childLots;
        },
        selectShape() {
            return this.$store.state.siteplan.openLeftNav.lot;
        },
        openLeftNav() {
            return this.$store.state.siteplan.openLeftNav;
        },
        lotList() {
            let list = [
                {
                    label: "Site Plan Lots",
                    value: "lots",
                    loading: false,
                    options: [],
                    children: [],
                    iconUrl: require("@/assets/sideIcons/lot-icon.svg"),
                },
                {
                    label: "Community Amenities",
                    value: "amenities",
                    options: [],
                    loading: false,
                    children: [],
                    iconUrl: require("@/assets/sideIcons/amenity-icon.svg"),
                },
                {
                    divider: true,
                },
                {
                    label: "Settings",
                    value: "settings",
                    options: [],
                    children: [],
                    iconUrl: require("@/assets/sideIcons/settings.svg"),
                    disabledSecondNav: true,
                },
            ];
            for (var i in this.siteplanLots) {
                let child = {
                    label: "",
                    value: "",
                    parent: "",
                    type: "",
                    options: [
                        {
                            label: "",
                            value: "edit",
                        },
                        {
                            label: "",
                            value: "delete",
                        },
                    ],
                };
                if (
                    this.siteplanLots[i].hasOwnProperty("inherit") &&
                    this.siteplanLots[i].inherit != ""
                ) {
                    if (typeof this.siteplanLots[i].inherit == "object") {
                        child.label = this.siteplanLots[i].inherit.name;
                    } else if (
                        this.childLots &&
                        this.siteplanLots[i] &&
                        this.siteplanLots[i].inherit &&
                        this.childLots[this.siteplanLots[i].inherit] &&
                        this.childLots[this.siteplanLots[i].inherit].name
                    ) {
                        child.label =
                            this.childLots[this.siteplanLots[i].inherit].name;
                    }
                } else {
                    child.label = this.siteplanLots[i].name;
                }
				if (!child.label || child.label.length == 0) child.label = '(Undefined lot)'
                child.type = this.siteplanLots[i].type;
                child.value = this.siteplanLots[i].id;
                if (child.type == "lot") {
                    child.parent = "lots";
                    child.options[0].label = "Edit Lot";
                    child.options[1].label = "Delete Lot";
                } else if (child.type == "info") {
                    child.parent = "amenities";
                    child.options[0].label = "Edit Amenity";
                    child.options[1].label = "Delete Amenity";
                }
                if (child.type == "lot") {
                    list[0].children.push(child);
                } else if (child.type == "info") {
                    list[1].children.push(child);
                }
            }
            list[0].children.sort((a, b) => {
                return a.label - b.label;
            });
            list[1].children.sort((a, b) => {
                return a.label - b.label;
            });

            return list;
        },
        siteplanLots() {
            return Object.values(this.$store.state.siteplan.lots);
        },
        instance() {
            return this.$store.state.instance;
        },
        appData() {
            return this.$store.state.siteplan.appData;
        },
    },
    methods: {
        scrollItem() {
            setTimeout(() => {
                let elmnt = document.querySelectorAll(
                    `[data-file-id="${this.selectShape}"]`
                );
                let list = document.getElementsByClassName("children-list");
                if (list && elmnt[0]) {
                    list[0].scroll(0, elmnt[0].offsetTop - 110);
                }
            }, 500);
        },
        bulkDelete(array) {
            if (this.$p < 40)
                return this.$message.error(
                    "You do not have permission to delete lots"
                );
            let self = this;
            this.$confirm({
                title: "Bulk Delete",
                content: (h) => (
                    <div>
                        Do you want to delete the selected Site Plan Lots?
                        Deleting will permanently remove the Site Plan Lots
                    </div>
                ),
                okText: "Delete",
                okType: "danger",
                cancelText: "Cancel",
                centered: true,
                onOk() {
                    self.confirmBulkDelete(array);
                }
            });
        },
        confirmBulkDelete(array) {
            this.loading = true;
            this.$api
                .put(
                    `/lots/:instance/${this.$store.state.siteplan.appData.id}/bulkdelete`,
                    array
                )
                .then(({ data }) => {
                    array.forEach((id) => {
                        let deleteObj = this.$store.state.siteplan.lots[id];
                        this.$store.commit("DELETE_LOT", deleteObj);
                    });
                    this.$store.commit("UPDATE_BULKDELETE", []);
                    this.loading = false;
                }).catch(err => {
					this.loading = false;
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err))
					}
				});
        },
        parentClicked(e) {
			const crumb = this.lotList.find(l => l.value === e.value)
			this.$store.commit("SET_CRUMBS", [crumb && crumb.label || e.value]);
			this.$store.commit("changeType", e.value);

			if ((e.value == "settings") && !this.$route.path.includes("settings")) {
				this.$router.push("/settings");
			} else if (
				(e.value == "lots" || e.value == "amenities") &&
				this.$route.meta.title != "Siteplan" &&
				this.$route.path != "/"
			) {
				this.$router.push("/");
			}
		},
        selectLot(data) {
            let selectedLot = {};
            for (var i = 0; i < this.siteplanLots.length; i++) {
                if (data.value == this.siteplanLots[i].id) {
                    selectedLot = this.siteplanLots[i];
                }
            }
            this.$store.commit("SET_CRUMBS", selectedLot.name);
            this.$store.commit("SELECT_LOT_SHAPE", selectedLot.shapeId);
        },
        moreOptions(data) {
            let editLot = {};
            if (data.optionValue == "edit") {
                for (var i in this.siteplanLots) {
                    if (this.siteplanLots[i].id == data.itemValue) {
                        editLot = this.siteplanLots[i];
                    }
                }
                if (data.parent == "lots") {
                    this.$store.commit("SHOW_EDIT_LOT", editLot);
                } else if (data.parent == "amenities") {
                    this.$store.commit("SHOW_EDIT_AMENITY", editLot);
                }
            }
            if (data.optionValue == "delete") {
                for (var i in this.siteplanLots) {
                    if (this.siteplanLots[i].id == data.itemValue) {
                        this.deleteObj = this.siteplanLots[i];
                    }
                }
                let self = this;
                if (this.$p < 40)
                    return this.$message.error(
                        "You do not have permission to delete lots"
                    );
                this.$confirm({
                    title: "Delete",
                    content: (h) => (
                        <div>
                            Do you want to delete this Site Plan Lot. Deleting
                            will permanently remove the Site Plan Lot
                        </div>
                    ),
                    okText: "Delete",
                    okType: "danger",
                    cancelText: "Cancel",
                    centered: true,
                    onOk() {
                        self.confirmDelete();
                    }
                });
            }
        },
        confirmDelete() {
            this.$store.commit("DELETE_LOT", this.deleteObj);
            this.$api.delete(
                `/lots/${this.instance.id}/${this.appData.id}/${this.deleteObj.id}`
            ).catch(err => {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err))
				}
			});
        },
        highlight(id) {
            document
                .querySelectorAll(".lot-highlight")
                .forEach((x) => x.classList.remove("lot-highlight"));
            if (
                document.querySelector(
                    `.imp-editor-shape.imp-editor-poly[data-id="${id}"]`
                )
            ) {
                document
                    .querySelector(
                        `.imp-editor-shape.imp-editor-poly[data-id="${id}"]`
                    )
                    .classList.add("lot-highlight");
            }
        },
        updateIds() {
            let ids = this.lots.map((x) => x.id);
            this.$api
                .put(
                    `/lots/${this.$store.state.instance.id}/${this.$store.state.siteplan.appData.id}/arrange-ids`,
                    ids
                )
                .then(() => {
                    this.$store.commit("REORDER_LOTS", ids);
                }).catch(err => {
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err))
					}
				});
        },
        editLot(lot) {
            this.$store.commit("SHOW_EDIT_LOT", lot);
        },
        handleClick(e) {
            this.$store.commit("changeFolder", e.key);
        },
    },
    created() {
        this.lots = Object.values(this.$store.state.siteplan.lots).sort(
            (a, b) => {
                if (a.order > b.order) return 1;
                return -1;
            }
        );
    },
};
</script>
